import { usePinia, useRoute, useRuntimeConfig } from '#imports'
import * as Sentry from '@sentry/nuxt'

const route = useRoute()
const runtimeConfig = useRuntimeConfig()
const sentryDsn = runtimeConfig.public.sentryDsn
let sentryTracesSampleRate: number | undefined = undefined
if (runtimeConfig.public.sentryTracesSampleRate) {
  sentryTracesSampleRate = parseFloat(runtimeConfig.public.sentryTracesSampleRate) || undefined
}

if (sentryDsn && route.meta.initSentry !== false) {
  Sentry.init({
    dsn: sentryDsn,
    environment: runtimeConfig.public.sentryEnvironment,
    tracesSampleRate: sentryTracesSampleRate,
    profilesSampleRate: sentryTracesSampleRate,
    replaysSessionSampleRate: sentryTracesSampleRate,
    replaysOnErrorSampleRate: 1.0,
    sendDefaultPii: true,
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
        unblock: ['.sentry-unblock, [data-sentry-unblock]'],
        unmask: ['.sentry-unmask, [data-sentry-unmask]'],
      }),
      Sentry.piniaIntegration(usePinia()),
    ],
  })
} else if (route.meta.initSentry === false) {
  console.log('Skipping Sentry init for this route')
} else if (!sentryDsn) {
  console.error('No Sentry DSN provided, skipping Sentry init')
}
